import { default as indexQDBQmhW9t6Meta } from "/home/node/client/.telus/pages/index.vue?macro=true";
import { default as country_45selectRUZ8OV9dL5Meta } from "/home/node/client/pages/country-select.vue?macro=true";
import { default as _91_46_46_46slug_93mSs9Smo4WhMeta } from "/home/node/client/pages/shop/[...slug].vue?macro=true";
import { default as indexXFHzzTqn00Meta } from "/home/node/client/pages/shop/advent-calendar/index.vue?macro=true";
import { default as _91slug_93WegZG3comqMeta } from "/home/node/client/pages/shop/brands/[slug].vue?macro=true";
import { default as indexaRZzOQuEw3Meta } from "/home/node/client/pages/shop/brands/index.vue?macro=true";
import { default as _91slug_93Y8ScPo0kcuMeta } from "/home/node/client/pages/shop/campaigns/[slug].vue?macro=true";
import { default as _91animalType_93JIT39z5rPOMeta } from "/home/node/client/pages/shop/campaigns/black-week/[animalType].vue?macro=true";
import { default as indexB5pyBKNhFeMeta } from "/home/node/client/pages/shop/campaigns/black-week/index.vue?macro=true";
import { default as indexs9y6zoe1NiMeta } from "/home/node/client/pages/shop/campaigns/index.vue?macro=true";
import { default as checkoutXiXOBYjJz7Meta } from "/home/node/client/pages/shop/checkout.vue?macro=true";
import { default as indexSF2jPFF0YxMeta } from "/home/node/client/pages/shop/dashboard/account-settings/index.vue?macro=true";
import { default as personal_45informationK1lrgxJGRMMeta } from "/home/node/client/pages/shop/dashboard/account-settings/personal-information.vue?macro=true";
import { default as _91id_93ziX0D1pBrVMeta } from "/home/node/client/pages/shop/dashboard/order-history/[id].vue?macro=true";
import { default as index8vj6lGFTH8Meta } from "/home/node/client/pages/shop/dashboard/order-history/index.vue?macro=true";
import { default as prescriptionsQe26GYsy7GMeta } from "/home/node/client/pages/shop/dashboard/prescriptions.vue?macro=true";
import { default as returnsB7TT9o8xldMeta } from "/home/node/client/pages/shop/dashboard/returns.vue?macro=true";
import { default as special_45offersow1FPyZfgdMeta } from "/home/node/client/pages/shop/dashboard/special-offers.vue?macro=true";
import { default as _91id_933uJOlwtu77Meta } from "/home/node/client/pages/shop/dashboard/subscriptions/[id].vue?macro=true";
import { default as indexzuRYw9xbQDMeta } from "/home/node/client/pages/shop/dashboard/subscriptions/index.vue?macro=true";
import { default as dashboardbeOZBhndB4Meta } from "/home/node/client/pages/shop/dashboard.vue?macro=true";
import { default as favoritesbKhaaTBmfxMeta } from "/home/node/client/pages/shop/favorites.vue?macro=true";
import { default as food_45consultationonyIXKSevZMeta } from "/home/node/client/pages/shop/food-consultation.vue?macro=true";
import { default as health_45checkpdU3eIDtRTMeta } from "/home/node/client/pages/shop/health-check.vue?macro=true";
import { default as indextobIe28DMUMeta } from "/home/node/client/pages/shop/index.vue?macro=true";
import { default as _91slug_93qa5aXN3ednMeta } from "/home/node/client/pages/shop/info/[slug].vue?macro=true";
import { default as indexJEMBUpuFjLMeta } from "/home/node/client/pages/shop/info/index.vue?macro=true";
import { default as loginRiyJwdfslsMeta } from "/home/node/client/pages/shop/login.vue?macro=true";
import { default as order_45returnuDQiAvkmEeMeta } from "/home/node/client/pages/shop/order-return.vue?macro=true";
import { default as _91slug_930QF8I0igRoMeta } from "/home/node/client/pages/shop/prescription/[brand]/[slug].vue?macro=true";
import { default as _91slug_93HJwuOUN59cMeta } from "/home/node/client/pages/shop/product/[slug].vue?macro=true";
import { default as receiptKOmvqH7SylMeta } from "/home/node/client/pages/shop/receipt.vue?macro=true";
import { default as recommended_45productsl4SJFm3O2lMeta } from "/home/node/client/pages/shop/recommended-products.vue?macro=true";
import { default as searchmBXBBJA9x8Meta } from "/home/node/client/pages/shop/search.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexQDBQmhW9t6Meta || {},
    component: () => import("/home/node/client/.telus/pages/index.vue")
  },
  {
    name: "country-select",
    path: "/country-select",
    meta: country_45selectRUZ8OV9dL5Meta || {},
    component: () => import("/home/node/client/pages/country-select.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexQDBQmhW9t6Meta || {},
    component: () => import("/home/node/client/.telus/pages/index.vue")
  },
  {
    name: "shop-slug___sv",
    path: "/sv/butik/:slug(.*)*",
    component: () => import("/home/node/client/pages/shop/[...slug].vue")
  },
  {
    name: "shop-slug___en",
    path: "/en/shop/:slug(.*)*",
    component: () => import("/home/node/client/pages/shop/[...slug].vue")
  },
  {
    name: "shop-advent-calendar___sv",
    path: "/sv/butik/djurkalender",
    meta: indexXFHzzTqn00Meta || {},
    component: () => import("/home/node/client/pages/shop/advent-calendar/index.vue")
  },
  {
    name: "shop-brands-slug___sv",
    path: "/sv/butik/varumarken/:slug()",
    meta: _91slug_93WegZG3comqMeta || {},
    component: () => import("/home/node/client/pages/shop/brands/[slug].vue")
  },
  {
    name: "shop-brands-slug___en",
    path: "/en/shop/brands/:slug()",
    meta: _91slug_93WegZG3comqMeta || {},
    component: () => import("/home/node/client/pages/shop/brands/[slug].vue")
  },
  {
    name: "shop-brands___sv",
    path: "/sv/butik/varumarken",
    component: () => import("/home/node/client/pages/shop/brands/index.vue")
  },
  {
    name: "shop-brands___en",
    path: "/en/shop/brands",
    component: () => import("/home/node/client/pages/shop/brands/index.vue")
  },
  {
    name: "shop-campaigns-slug___sv",
    path: "/sv/butik/kampanjer/:slug()",
    component: () => import("/home/node/client/pages/shop/campaigns/[slug].vue")
  },
  {
    name: "shop-campaigns-slug___en",
    path: "/en/shop/campaigns/:slug()",
    component: () => import("/home/node/client/pages/shop/campaigns/[slug].vue")
  },
  {
    name: "shop-campaigns-black-week-animalType___sv",
    path: "/sv/butik/kampanjer/black-week/:animalType()",
    component: () => import("/home/node/client/pages/shop/campaigns/black-week/[animalType].vue")
  },
  {
    name: "shop-campaigns-black-week___sv",
    path: "/sv/butik/kampanjer/black-week",
    meta: indexB5pyBKNhFeMeta || {},
    component: () => import("/home/node/client/pages/shop/campaigns/black-week/index.vue")
  },
  {
    name: "shop-campaigns___sv",
    path: "/sv/butik/kampanjer",
    component: () => import("/home/node/client/pages/shop/campaigns/index.vue")
  },
  {
    name: "shop-campaigns___en",
    path: "/en/shop/campaigns",
    component: () => import("/home/node/client/pages/shop/campaigns/index.vue")
  },
  {
    name: "shop-checkout___sv",
    path: "/sv/butik/kassa",
    meta: checkoutXiXOBYjJz7Meta || {},
    component: () => import("/home/node/client/pages/shop/checkout.vue")
  },
  {
    name: "shop-checkout___en",
    path: "/en/shop/checkout",
    meta: checkoutXiXOBYjJz7Meta || {},
    component: () => import("/home/node/client/pages/shop/checkout.vue")
  },
  {
    name: "shop-dashboard___sv",
    path: "/sv/butik/mina-sidor",
    meta: dashboardbeOZBhndB4Meta || {},
    component: () => import("/home/node/client/pages/shop/dashboard.vue"),
    children: [
  {
    name: "shop-dashboard-account-settings___sv",
    path: "kontoinstallningar",
    component: () => import("/home/node/client/pages/shop/dashboard/account-settings/index.vue")
  },
  {
    name: "shop-dashboard-account-settings-personal-information___sv",
    path: "kontoinstallningar/personuppgifter",
    component: () => import("/home/node/client/pages/shop/dashboard/account-settings/personal-information.vue")
  },
  {
    name: "shop-dashboard-order-history-id___sv",
    path: "orderhistorik/:id()",
    component: () => import("/home/node/client/pages/shop/dashboard/order-history/[id].vue")
  },
  {
    name: "shop-dashboard-order-history___sv",
    path: "orderhistorik",
    component: () => import("/home/node/client/pages/shop/dashboard/order-history/index.vue")
  },
  {
    name: "shop-dashboard-prescriptions___sv",
    path: "recept",
    meta: prescriptionsQe26GYsy7GMeta || {},
    component: () => import("/home/node/client/pages/shop/dashboard/prescriptions.vue")
  },
  {
    name: "shop-dashboard-returns___sv",
    path: "returer",
    component: () => import("/home/node/client/pages/shop/dashboard/returns.vue")
  },
  {
    name: "shop-dashboard-special-offers___sv",
    path: "erbjudanden",
    component: () => import("/home/node/client/pages/shop/dashboard/special-offers.vue")
  },
  {
    name: "shop-dashboard-subscriptions-id___sv",
    path: "prenumerationer/:id()",
    component: () => import("/home/node/client/pages/shop/dashboard/subscriptions/[id].vue")
  },
  {
    name: "shop-dashboard-subscriptions___sv",
    path: "prenumerationer",
    component: () => import("/home/node/client/pages/shop/dashboard/subscriptions/index.vue")
  }
]
  },
  {
    name: "shop-favorites___sv",
    path: "/sv/butik/favorites",
    component: () => import("/home/node/client/pages/shop/favorites.vue")
  },
  {
    name: "shop-food-consultation___sv",
    path: "/sv/butik/foderradgivning",
    component: () => import("/home/node/client/pages/shop/food-consultation.vue")
  },
  {
    name: "shop-health-check___sv",
    path: "/sv/butik/health-check",
    meta: health_45checkpdU3eIDtRTMeta || {},
    component: () => import("/home/node/client/pages/shop/health-check.vue")
  },
  {
    name: "shop-health-check___en",
    path: "/en/shop/health-check",
    meta: health_45checkpdU3eIDtRTMeta || {},
    component: () => import("/home/node/client/pages/shop/health-check.vue")
  },
  {
    name: "shop___sv",
    path: "/sv/butik",
    meta: indextobIe28DMUMeta || {},
    component: () => import("/home/node/client/pages/shop/index.vue")
  },
  {
    name: "shop___en",
    path: "/en/shop",
    meta: indextobIe28DMUMeta || {},
    component: () => import("/home/node/client/pages/shop/index.vue")
  },
  {
    name: "shop-info-slug___sv",
    path: "/sv/butik/info/:slug()",
    meta: _91slug_93qa5aXN3ednMeta || {},
    component: () => import("/home/node/client/pages/shop/info/[slug].vue")
  },
  {
    name: "shop-info-slug___en",
    path: "/en/shop/info/:slug()",
    meta: _91slug_93qa5aXN3ednMeta || {},
    component: () => import("/home/node/client/pages/shop/info/[slug].vue")
  },
  {
    name: "shop-info___sv",
    path: "/sv/butik/info",
    meta: indexJEMBUpuFjLMeta || {},
    component: () => import("/home/node/client/pages/shop/info/index.vue")
  },
  {
    name: "shop-info___en",
    path: "/en/shop/info",
    meta: indexJEMBUpuFjLMeta || {},
    component: () => import("/home/node/client/pages/shop/info/index.vue")
  },
  {
    name: "shop-login___sv",
    path: "/sv/butik/logga-in",
    meta: loginRiyJwdfslsMeta || {},
    component: () => import("/home/node/client/pages/shop/login.vue")
  },
  {
    name: "shop-order-return___sv",
    path: "/sv/butik/returer",
    component: () => import("/home/node/client/pages/shop/order-return.vue")
  },
  {
    name: "shop-order-return___en",
    path: "/en/shop/order-return",
    component: () => import("/home/node/client/pages/shop/order-return.vue")
  },
  {
    name: "shop-prescription-brand-slug___sv",
    path: "/sv/butik/produkt/\\:brand/:slug()",
    meta: _91slug_930QF8I0igRoMeta || {},
    component: () => import("/home/node/client/pages/shop/prescription/[brand]/[slug].vue")
  },
  {
    name: "shop-product-slug___sv",
    path: "/sv/butik/produkt/:slug()",
    meta: _91slug_93HJwuOUN59cMeta || {},
    component: () => import("/home/node/client/pages/shop/product/[slug].vue")
  },
  {
    name: "shop-product-slug___en",
    path: "/en/shop/product/:slug()",
    meta: _91slug_93HJwuOUN59cMeta || {},
    component: () => import("/home/node/client/pages/shop/product/[slug].vue")
  },
  {
    name: "shop-receipt___sv",
    path: "/sv/butik/kvitto",
    component: () => import("/home/node/client/pages/shop/receipt.vue")
  },
  {
    name: "shop-receipt___en",
    path: "/en/shop/receipt",
    component: () => import("/home/node/client/pages/shop/receipt.vue")
  },
  {
    name: "shop-recommended-products___sv",
    path: "/sv/butik/rekommenderade-produkter",
    component: () => import("/home/node/client/pages/shop/recommended-products.vue")
  },
  {
    name: "shop-search___sv",
    path: "/sv/butik/sok",
    component: () => import("/home/node/client/pages/shop/search.vue")
  },
  {
    name: "shop-search___en",
    path: "/en/shop/search",
    component: () => import("/home/node/client/pages/shop/search.vue")
  },
  {
    name: "country-select",
    path: "/",
    component: () => import("/home/node/client/pages/country-select.vue")
  }
]