const pageTitles = {
  en: {
    'shipping-and-delivery': 'Shipping and delivery',
    'returns-and-refunds': 'Returns and refunds',
    'terms-of-purchase': 'Terms of purchase',
  },
} as const;

const pageSlugTranslations = {
  'shipping-and-delivery': {
    en: 'shipping-and-delivery',
  },
  'returns-and-refunds': {
    en: 'returns-and-refunds',
  },
  'terms-of-purchase': {
    en: 'terms-of-purchase',
  },
} as const;

export { pageTitles, pageSlugTranslations };
